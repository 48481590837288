<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  type="product"
                  :plan1-active="plan1Active"
                  :plan1-details="plan1Details"
                  :plan2-title="plan2Title"
                  :plan2-list="plan2List"
                  :plan3-list="plan3List"
                  />
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Product3',
  components: {
   PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '产品服务',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/03.jpg'), txt: '智慧迎新系统', txt2: '迎新有温度', txt5: '数据多跑路、师生少跑路、', jumpUrl: 'btn'},
      ],
      plan1Active: Public.product3().routerName,
      // 当前方案概述详情
      plan1Details: Public.product3(),
      plan2Title: {
        title: '产品亮点', txt: 'Product Highlights'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/28.fw.png'), title: '新生入学秩序化', txt: '学校按每日新生到校数量，提前协调资源和相关人员，有序安排'},
        {img: require('@/assets/images/27.fw.png'), title: '清单化的线上引导', txt: '结合新生的入校点位信息，院系信息，用智能化设备、清单化引导'},
        {img: require('@/assets/images/26.fw.png'), title: '身份核验', txt: '人证档一键核验，不聚集，快速通过后自动展示宿舍信息，快速入住'},
        {img: require('@/assets/images/25.fw.png'), title: '迎新数据大屏', txt: '迎新数据收集展示，实时展示入校人数，明确工作进度'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/32.fw.png'), title: '新乡学院', txt: '是一所经教育部批准建立的公办全日制普通本科院校。学校始建于1949年'},
        {img: require('@/assets/images/school/kjxy.jpg'), title: '河南科技学院', txt: '河南科技学院是一所省属普通本科院校。学校始建于1939年'},
        {img: require('@/assets/images/school/xg.jpg'), title: '新乡工程学院', txt: '是一所全日制民办普通本科高校。前身是创建于2003年的河南职业技术师范学院新科学院'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
